/** @jsx jsx */
import { Global, jsx, css } from '@emotion/core';
import { assign, Machine } from 'xstate';
import { useMachine } from '@xstate/react';

interface Ctx {
  current: number;
  items: string[];
}

const machine = Machine<Ctx>(
  {
    initial: 'autoAdvance',

    context: {
      current: 0,
      items: [],
    },

    on: {
      PREV: [
        { target: 'manual', actions: 'prev', cond: ctx => ctx.current > 0 },
        { target: 'manual', actions: 'last' },
      ],

      NEXT: [
        {
          target: 'manual',
          actions: 'next',
          cond: ctx => ctx.current < ctx.items.length - 1,
        },
        { target: 'manual', actions: 'first' },
      ],
    },

    states: {
      manual: {},

      autoAdvance: {
        after: {
          3000: {
            target: 'autoAdvance',
            actions: 'cycle',
          },
        },
      },
    },
  },
  {
    actions: {
      prev: assign(ctx => ({ current: ctx.current - 1 })),
      next: assign(ctx => ({ current: ctx.current + 1 })),

      first: assign(ctx => ({ current: 0 })),
      last: assign(ctx => ({ current: ctx.items.length - 1 })),

      cycle: assign(ctx => ({ current: (ctx.current + 1) % ctx.items.length })),
    },
  },
);

interface Props {
  items: string[];
}

export function Slideshow(props: Props) {
  const [state, send] = useMachine(
    machine.withContext({
      current: 0,
      items: props.items,
    }),
  );

  return (
    <div css={{ position: 'relative' }}>
      <div
        css={{
          background: '#84B09C',
          padding: 10,
          margin: '0 60px',

          '@media (max-width: 700px)': {
            margin: '0 40px',
          },
        }}
      >
        <img
          src={state.context.items[state.context.current]}
          width="2000"
          height="1362"
          css={{ display: 'block', width: '100%', height: 'auto' }}
        />
      </div>

      <button
        css={[
          {
            background: `no-repeat url(${require('./images/prev.png')})`,
            left: '10px',

            '@media (max-width: 700px)': {
              // left: 0,
            },
          },
          button,
        ]}
        onClick={() => send('PREV')}
      >
        Letztes Foto
      </button>
      <button
        css={[
          {
            background: `no-repeat url(${require('./images/next.png')})`,
            right: '10px',

            '@media (max-width: 700px)': {
              // right: 0,
            },
          },
          button,
        ]}
        onClick={() => send('NEXT')}
      >
        Nächstes Foto
      </button>
    </div>
  );
}

const button = css({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  textIndent: '-999em',
  overflow: 'hidden',
  border: 0,
  backgroundSize: '40px auto',
  padding: 0,
  height: 60,
  width: 40,
  outline: 0,

  ':hover': {
    opacity: 0.8,
  },

  '@media (max-width: 700px)': {
    backgroundSize: '20px auto',
    width: 20,
  },
});
