/** @jsx jsx */
import { css, Global, jsx } from "@emotion/core";
import styled from "@emotion/styled";
import cx from "classnames";
import { fromPairs, keys, update } from "ramda";
import repeat from "ramda/es/repeat";
import * as React from "react";
import { SweepstakesSubtitle, SweepstakesTitle } from "./Blocks";
import { center, Color, infoQuestions, narrow, questions } from "./config";
import Grid, { Column, Row } from "./Layout";
import * as theme from "./Sweepstakes.module.css";

const isEmpty = (value: null | string) => value == null || value.length === 0;
const isFalse = (value: null | boolean) => value == null || value === false;

declare var process: any;

const publicUrl = process.env.PUBLIC_URL;
const apiUrl =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8000/gewinnspiel/teilnehmen/"
    : `${publicUrl}/gewinnspiel/teilnehmen/`;

interface Errors {
  [key: string]: string[];
}

interface State {
  form: {
    [key: string]: any;
  };
  loading: boolean;
  success: boolean;
  errors: any;
  answers: Array<undefined | number>;
}

export class Sweepstakes extends React.Component<any, State> {
  state: State = {
    form: {},
    loading: false,
    success: false,
    errors: null,
    answers: repeat(undefined, questions.length)
  };

  handleChange = (field: string) => (event: any) => {
    this.setState({
      form: {
        ...this.state.form,
        [field]: event.target.value
      }
    });
  };
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleCheck = (field: string) => (event: any) => {
    this.setState({
      form: {
        ...this.state.form,
        [field]: event.target.checked
      }
    });
  };

  handleAnswer = (question: number, answer: number, value: string) => () => {
    this.setState({
      answers: update(question, answer, this.state.answers),
      form: {
        ...this.state.form,
        [`question_${question + 1}`]: value
      }
    });
  };

  handleSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    const fields = {
      ...this.state.form
    };

    this.setState({
      loading: true,
      errors: null,
      success: false
    });
    fetch(apiUrl, {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json"
      }),
      body: JSON.stringify(fields)
    }).then(
      response => {
        response.json().then(jsonResponse => {
          console.log("request processed:", jsonResponse);
          if (jsonResponse.status === "failure") {
            // const errors = jsonResponse.errors as Errors;
            const errorKeys = keys(jsonResponse.errors);
            const errorPairs = errorKeys.map(
              // @ts-ignore
              (key: string): [string, boolean] => [key, true]
            );

            this.setState({
              loading: false,
              success: false,
              errors: fromPairs(errorPairs)
            });
          } else {
            this.setState({ loading: false, success: true });
          }
        });
      },
      response => {
        console.error("form error:", response);
      }
    );
  };

  render() {
    const { form, loading, success, errors } = this.state;

    const questionsAnswered = this.state.answers.every(
      answer => answer !== undefined
    );

    const formIsValid =
      isEmpty(form.first_name) === false &&
      isEmpty(form.last_name) === false &&
      isEmpty(form.email) === false &&
      isFalse(form.terms) === false;

    return (
      <Container onClick={event => event.stopPropagation()}>
        {/* <Link className={theme.Back} to="/">
          Zurück
        </Link> */}
        {/* <div className={theme.Vignette} /> */}
        <SweepstakesTitle>Teilnahme</SweepstakesTitle>
        <SweepstakesSubtitle>Dein Wissen ist gefragt</SweepstakesSubtitle>

        {success === false ? (
          <div className={theme.Content}>
            <form
              method="post"
              onSubmit={this.handleSubmit}
              className={theme.Form}
            >
              <p>
                Um am Gewinnspiel teilnehmen und die Reise nach Costa Rica
                gewinnen zu können, musst du nur die folgende Frage richtig
                beantworten. Viel Glück dabei!
              </p>
              {questions.map((question, questionIndex) => (
                <div className={theme.Question} key={questionIndex}>
                  <h2>{question.title}</h2>

                  {question.answers.map((answer, answerIndex) => (
                    <label className={theme.FormCheckbox} key={answerIndex}>
                      <input
                        type="radio"
                        name={`question_${questionIndex + 1}`}
                        value={answer.value}
                        onChange={this.handleAnswer(
                          questionIndex,
                          answerIndex,
                          answer.value
                        )}
                      />
                      {answer.title}
                    </label>
                  ))}
                </div>
              ))}
              <div
                className={cx(theme.FormContainer, {
                  [theme.Disabled]: !questionsAnswered
                })}
              >
                <Grid>
                  <Row>
                    <Column span={2} style={{ minWidth: 100 }}>
                      <label className={theme.FormCheckbox}>
                        <input
                          disabled={loading}
                          type="radio"
                          value="hr"
                          name="title"
                          onChange={this.handleChange("title")}
                        />
                        Herr
                      </label>
                    </Column>
                    <Column span={2} style={{ minWidth: 100 }}>
                      <label className={theme.FormCheckbox}>
                        <input
                          disabled={loading}
                          type="radio"
                          value="fr"
                          name="title"
                          onChange={this.handleChange("title")}
                        />
                        Frau
                      </label>
                    </Column>
                    <Column span={2} style={{ minWidth: 100 }}>
                      <label className={theme.FormCheckbox}>
                        <input
                          disabled={loading}
                          type="radio"
                          value="divers"
                          name="title"
                          onChange={this.handleChange("title")}
                        />
                        divers*
                      </label>
                    </Column>
                  </Row>

                  <Row>
                    <input
                      disabled={loading}
                      className="InputText"
                      type="text"
                      placeholder="Nachname*"
                      onChange={this.handleChange("last_name")}
                    />
                  </Row>
                  <Row>
                    <input
                      disabled={loading}
                      className="InputText"
                      type="text"
                      placeholder="Vorname*"
                      onChange={this.handleChange("first_name")}
                    />
                  </Row>
                  <Row>
                    <Column span={10} style={{ paddingRight: 20 }}>
                      <input
                        disabled={loading}
                        className="InputText InputText__street"
                        type="text"
                        placeholder="Straße"
                        onChange={this.handleChange("street_name")}
                      />
                    </Column>
                    <Column span={2}>
                      <input
                        disabled={loading}
                        className="InputText InputText__no"
                        type="text"
                        placeholder="Nr."
                        onChange={this.handleChange("street_number")}
                      />
                    </Column>
                  </Row>
                  <Row>
                    <Column span={4} style={{ paddingRight: 20 }}>
                      <input
                        disabled={loading}
                        className="InputText InputText__zip"
                        type="text"
                        placeholder="PLZ"
                        onChange={this.handleChange("zip_code")}
                      />
                    </Column>
                    <Column span={8}>
                      <input
                        disabled={loading}
                        className="InputText InputText__city"
                        type="text"
                        placeholder="Ort"
                        onChange={this.handleChange("city")}
                      />
                    </Column>
                  </Row>
                  <Row>
                    <input
                      disabled={loading}
                      style={
                        errors != null && "email" in errors
                          ? { outline: "1px solid red" }
                          : undefined
                      }
                      type="text"
                      placeholder="E-Mail-Adresse*"
                      onChange={this.handleChange("email")}
                    />
                  </Row>
                </Grid>

                <div className={theme.FormHint}>* Pflichtfeld</div>

                <label className={theme.FormCheckbox}>
                  <input
                    disabled={loading}
                    type="checkbox"
                    name="terms"
                    onChange={this.handleCheck("terms")}
                  />
                  Die{" "}
                  <a
                    css={{ textDecoration: "underline" }}
                    href="https://www.globetrotter.de/service/agb/#gewinnspiele"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Teilnahmebedingungen
                  </a>{" "}
                  und{" "}
                  <a
                    css={{ textDecoration: "underline" }}
                    href="https://www.globetrotter.de/service/agb/#datenschutz"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Datenschutzbestimmungen
                  </a>{" "}
                  habe ich gelesen und erkenne ich an.*
                </label>

                {infoQuestions.map((question, index) => (
                  <React.Fragment key={index}>
                    <label className={theme.FormCheckbox} key={index}>
                      <input
                        disabled={loading}
                        type="checkbox"
                        name={question.id}
                        onChange={this.handleCheck(question.id)}
                      />
                      {question.text}
                    </label>
                  </React.Fragment>
                ))}

                <button
                  type="submit"
                  className={theme.Submit}
                  disabled={formIsValid === false || loading === true}
                >
                  Senden
                </button>
              </div>
            </form>
          </div>
        ) : (
          <div className={theme.Content} style={{ marginBottom: 80 }}>
            <p>Vielen Dank für Deine Teilnahme.</p>
          </div>
        )}

        <Grid>
          <Global
            styles={css`
              @media (max-width: 700px) {
                .conditions,
                .logos {
                  width: 100% !important;
                }
              }
            `}
          />
          <Column className="conditions" span={12}>
            <Conditions style={{ margin: "2rem 0 4rem" }}>
              Zu gewinnen gibt es eine 8-tägige Reise für zwei Personen.
              Veranstalter ist das Costa Rica Tourism Board. Flug mit Condor ab
              Frankfurt (Economy Class), Hotels (Doppelzimmer) mit Frühstück und
              Transfers im Land sind enthalten. Es gelten die Reisebedingungen
              der jeweiligen Veranstalter bzw. Fluggesellschaften. Alle Angaben
              ohne Gewähr, der Rechtsweg ist ausgeschlossen. Teilnahmeschluss
              ist der 31.1.2020.
            </Conditions>
          </Column>
        </Grid>

        <ShopTeaser>
          <p>
            Hier findest Du einen kleinen Travelguide für Deinen Costa Rica-Trip
            als Pdf-Download:
          </p>
          <ShopLink
            href={require("./downloads/costa-rica-travelguide.pdf")}
            rel="noopener noreferrer"
            target="_blank"
          >
            Download PDF
          </ShopLink>
        </ShopTeaser>

        <div
          css={[
            center,
            {
              padding: "3rem 0",
              borderBottom: "4px dashed #979797"
            }
          ]}
        >
          Lust auf mehr? Live-Kontakt nach Costa Rica auf Deutsch:
          <div
            css={[
              narrow,
              {
                display: "grid",
                gridTemplateColumns: "1fr 1fr",

                "@media (max-width: 700px)": {
                  gridTemplateColumns: "none",
                  gridTemplateRows: "1fr 1fr"
                }
              }
            ]}
          >
            <a
              href="https://www.facebook.com/visitcostaricade/"
              target="_blank"
              css={social}
            >
              <img src={require("./images/fb.png")} alt="Facebook Logo" />
              @visitcostaricade
            </a>
            <a
              href="https://www.instagram.com/visit_costaricade/"
              target="_blank"
              css={social}
            >
              <img src={require("./images/insta.png")} alt="Instagram Logo" />
              @visit_costaricade
            </a>
          </div>
        </div>
        <div>
          <div css={[narrow, center]}>
            <a
              href="https://www.facebook.com/visitcostaricade/"
              target="_blank"
              css={logos}
            >
              <img
                src={require("./images/costa-rica-logo.png")}
                alt="Costa Rica"
              />
            </a>
          </div>

          <div css={[narrow, center]}>
            <a href="https://www.condor.com/" target="_blank" css={logos}>
              <img src={require("./images/condor.png")} alt="Condor" />
            </a>
          </div>
        </div>
      </Container>
    );
  }
}

const Container = styled.div`
  max-width: 900px;
  margin: 0 auto;
  padding-left: 2rem;
  padding-right: 2rem;

  @media (max-width: 700px) {
    padding: 40px;
  }
`;

const Conditions = styled.div`
  font-size: 12px;
  line-height: 18px;
`;

const ShopTeaser = styled.div`
  padding: 3rem 0;
  border-top: 4px dashed #979797;
  border-bottom: 4px dashed #979797;

  text-align: center;
  font-weight: 700;
  text-transform: uppercase;

  p {
    margin-top: 0;

    font-weight: 700;
    font-size: 24px;
    color: ${Color.Green};
    letter-spacing: 4.73px;
  }

  aside {
    font-weight: bold;
    font-size: 1rem;
    color: #000000;
    letter-spacing: 4.73px;
    text-align: center;
    text-transform: uppercase;
  }
`;

const ShopLink = styled.a`
  clear: both;
  display: inline-block;
  width: auto;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;

  font-size: 1.5rem;

  background: #d8d8d8;
  padding: 0 20px;
  line-height: 2.5rem;
  color: ${Color.Green};

  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;

  em {
    text-decoration: underline;
    font-family: Open Sans;
    font-weight: 800;
    font-size: 3rem;
    font-style: normal;
    color: #3c434c;
  }
`;

const social = css({
  "> img": {
    display: "block",
    margin: "3rem auto 1rem",
    maxWidth: "100%"
  }
});

const logos = css({
  "> img": {
    display: "block",
    margin: "3rem auto 1rem",
    width: "100%",
    maxWidth: 300
  }
});
