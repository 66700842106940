/** @jsx jsx */
import { Global, jsx, css } from '@emotion/core';
import {
  Color,
  fonts,
  globalStyles,
  lora,
  center,
  oswald,
  narrow,
  header,
  imagePane,
} from './config';
import { useGoogleFonts } from './useGoogleFonts';
import { useViewport } from './useViewport';
import React from 'react';
import Grid, { Column } from './Layout';
import styled from '@emotion/styled';
import { useMachine } from '@xstate/react';
import { Machine } from 'xstate';
import { Sweepstakes } from './Sweepstakes';
import { Slideshow } from './Slideshow';

const machine = Machine({
  initial: 'idle',

  context: {
    player: new Audio(),
  },

  states: {
    idle: {
      on: {
        tukan: { target: 'tukan', actions: ['play'] },
        affe: { target: 'affe', actions: ['play'] },
        frosch: { target: 'frosch', actions: ['play'] },
      },
    },
    tukan: {
      on: {
        tukan: { target: 'idle', actions: ['pause'] },
        affe: { target: 'affe', actions: ['play'] },
        frosch: { target: 'frosch', actions: ['play'] },
      },
    },
    affe: {
      on: {
        tukan: { target: 'tukan', actions: ['play'] },
        affe: { target: 'idle', actions: ['pause'] },
        frosch: { target: 'frosch', actions: ['play'] },
      },
    },
    frosch: {
      on: {
        tukan: { target: 'tukan', actions: ['play'] },
        affe: { target: 'affe', actions: ['play'] },
        frosch: { target: 'idle', actions: ['pause'] },
      },
    },
  },
});

export function App(props: {}) {
  const fontsLoaded = useGoogleFonts(fonts);
  const viewport = useViewport();
  const [state, send] = useMachine(
    machine.withConfig({
      actions: {
        pause(ctx) {
          ctx.player.pause();
        },
        play(ctx, event) {
          if (event.type === 'tukan') {
            ctx.player.src = require('./audio/tukan.mp3');
          }
          if (event.type === 'affe') {
            ctx.player.src = require('./audio/affe.mp3');
          }
          if (event.type === 'frosch') {
            ctx.player.src = require('./audio/frosch.mp3');
          }
          ctx.player.play();
        },
      },
    }),
  );

  if (viewport === undefined) return null;

  return (
    <React.Fragment>
      <Global styles={globalStyles} />
      <div
        css={{
          visibility: fontsLoaded ? 'visible' : 'hidden',
          position: 'relative',
          maxWidth: 1156,
          margin: '0 auto',
        }}
      >
        <div
          css={{
            backgroundColor: Color.Green,
            backgroundImage: `url(${require('./images/top.png')})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            color: Color.White,
            padding: '0.5rem 2rem 2rem 2rem',
          }}
        >
          <div>
            <h1
              css={{
                fontSize: '89px',
                lineHeight: '120px',
                letterSpacing: '3.53px',
                textAlign: 'center',

                '@media (max-width: 700px)': {
                  fontSize: 50,
                  lineHeight: '80px',
                },
              }}
            >
              ¡PURA VIDA!
            </h1>

            <p
              css={[
                {
                  letterSpacing: '3.59px',
                },
                center,
                narrow,
              ]}
            >
              Costa Rica ist ein Ganzjahres-Reiseziel. Das Land versteht sich
              als ökologische Vorzeigenation Lateinamerikas. Unter jedem Blatt
              und hinter jedem Baum gibt’s etwas zu entdecken. Schau dir unsere
              inspirierenden Infos an und beantworte die Gewinnfrage am Ende der
              Seite. Mit ein wenig Glück machst du dich schon bald auf in dieses
              Abenteuerland!
            </p>
          </div>
        </div>

        <div
          css={[{ background: Color.Background, padding: '3rem 0' }, center]}
        >
          <h2 css={[oswald, narrow, header, { marginBottom: '2rem' }]}>
            Fluch der Karibik? – Welcher Fluch?
          </h2>

          <iframe
            src="https://player.vimeo.com/video/373074703"
            width="100%"
            height="360"
            allow="autoplay; fullscreen"
            allowFullScreen
            frameBorder={0}
          />

          <p css={[lora, narrow]}>
            Costa Rica ist ein beliebter Drehort. Fluch der Karibik und Jurassic
            Park sind zum Beispiel mit Costa Rica verknüpft. Um manche
            Verknüpfungen aufzuspüren, musst du tief in die Regen- und
            Nebelwälder eintauchen. In diesem Film bist du der Hauptdarsteller.
            Er geht solange wie dein Costa-Rica-Urlaub. Die Handlung? Offen. Das
            Drehbuch? Schreibst du jeden Tag neu. Die Kulissen? Rauchende
            Vulkane, geheimnisvolle Urwälder, unberührte Kordilleren. Film ab!
          </p>
        </div>

        <div
          css={{
            background: `url(${require('./images/forest.jpg')})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            padding: '8rem 0',
          }}
        >
          <div css={[center, { background: Color.White, padding: '4rem 0' }]}>
            <p css={[lora, narrow]}>
              Roadmovies beginnen oft mit einer Actionszene. Also reisen wir
              zuerst zu einem der aktiven Vulkane. In der Provinz Guanacaste im
              Nordwesten des Landes dampft der Rincón de la Vieja stinkende
              Wolken aus. Der Säuresee des Hauptkraters ist für Besucher tabu.
              Doch an seinen Hängen führen Wanderwege durch den tropischen
              Regenwald. Hier kocht eine Quelle, dort blubbert ein Schlammloch
              und da pfeift es aus dem aufgerissenen Boden.
            </p>

            <strong
              css={[oswald, narrow, { margin: '2rem auto', display: 'block' }]}
            >
              Die ganze Affenbande brüllt
            </strong>

            <p css={[lora, narrow]}>
              Ringsum Bäume. Riesige Bäume. Hoch oben schwingen Totenkopfäffchen
              von Ast zu Ast. Erinnerst du dich an Jack, das fiese
              Piratenäffchen aus Fluch der Karibik? Genau solche gibt es hier.
              In der Natur sind sie friedlich. Anders ist es, wenn du es mit
              einer Bande Geoffroy-Klammeraffen zu tun bekommst. Die werfen
              schon mal Stöckchen oder Pinkeln auf einem herab. Also weiter.
            </p>
          </div>
        </div>

        <div>
          <h2 css={[oswald, header, center]}>So klingt Pura Vida</h2>

          <div
            css={[
              {
                display: 'grid',
                gridGap: 9,
                gridTemplateColumns: '1fr 1fr 1fr',

                '@media (max-width: 700px)': {
                  gridTemplateColumns: 'none',
                  gridTemplateRows: '1fr 1fr 1fr',
                },
              },
              center,
            ]}
          >
            <SoundBox
              title="Tukan"
              content="In Costa Rica leben zehn Prozent aller bekannten Vogelarten. Für Hobby-Ornithologen ein Paradies. Höre hier wie der Tukan klingt!"
              image={require('./images/circle-tukan.png')}
              background={require('./images/box-1.png')}
              onClick={() => send({ type: 'tukan' })}
            />

            <SoundBox
              title="Brüllaffe"
              content="Die ganze Affenbande brüllt! Wenn die Brüllaffen loslegen, möchte man am liebsten reißaus nehmen. Oder dem Schauspiel fasziniert zusehen und -hören!"
              image={require('./images/circle-affe.png')}
              background={require('./images/box-1.png')}
              onClick={() => send({ type: 'affe' })}
            />

            <SoundBox
              title="Rotaugenlaubfrosch"
              content="In Costa Rica leben rund 160 Amphibienarten. Besonders häufig sind Frösche. Wie zum Beispiel der Rotaugenlaubfrosch."
              image={require('./images/circle-frosch.png')}
              background={require('./images/box-1.png')}
              onClick={() => send({ type: 'frosch' })}
            />
          </div>
        </div>

        <div>
          <div
            css={{
              display: 'grid',
              gridTemplateColumns: '5fr 6fr',
              margin: '9px 0',

              '@media (max-width: 700px)': {
                gridTemplateColumns: 'none',
                gridTemplateRows: '5fr 6fr',
              },
            }}
          >
            <div css={{ padding: '3rem 2rem' }}>
              <h2 css={[oswald, header]}>Aber wohin?</h2>

              <p css={lora}>
                In einer heißen Quelle planschen? Zur Playa Samara und das
                Handtuch am weißen Sandstrand auslegen? Oder zu den breiten
                Wasserfällen Llanos del Cortez? Spannend ist auch der Meerespark
                Las Baulas auf der Nicoya-Halbinsel. Dort schützt man die mit
                bis zu zweieinhalb Metern größte Schildkröte der Welt, die Mitte
                Oktober bis Mitte Februar ihre Eier vom warmen Sand ausbrüten
                lässt. An die Pazifikküste also! – Oder nein! Doch an die
                Karibikküste, Karibik klingt so schön nach Urlaub! … Als
                Strandliebhaber hat man wirklich die Qual der Wahl!
              </p>
            </div>
            <div
              css={{
                backgroundImage: `url(${require('./images/baden.jpg')})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                height: '100%',
              }}
            ></div>
          </div>
        </div>

        <div
          css={[
            center,
            {
              background: '#D7E5DD',
              padding: '6rem 2rem',
            },
          ]}
        >
          <h2 css={[oswald, narrow, header, { marginBottom: '2rem' }]}>
            Überall Tiere, grünes Buschwerk und Vogelgesang
          </h2>

          <p css={[lora, narrow]}>
            Landeinwärts riegeln Feuerberge von der Karibik heraufziehende
            Wetter ab. Der schöne Arenal, der rauchende Poás, der schlafende
            Barva und der mächtige Irazú. Alle sind mit Regenwäldern bedeckt.
            Alle sind ein Refugium für seltene Tier- und Pflanzenarten. Erinnern
            die bis zu 1,5 Meter langen Grünen Leguane nicht an kleine Dinos aus
            Jurassic Park? Die Filme spielen auf einer Insel vor Costa Rica.
            Weit draußen im Pazifik gibt es die Kokosinsel. Es halten sich
            hartnäckig die Gerüchte, dass Piraten auf dem Eiland ihre Beute
            vergraben haben. Nicht Jack Sparrow – echte Freibeuter.
          </p>
        </div>

        <div>
          <h2 css={[oswald, center, header]}>Am Nabel der Welt</h2>

          <div
            css={{
              display: 'grid',
              gridGap: 9,
              gridTemplateColumns: '1fr 2fr',

              '@media (max-width: 700px)': {
                gridTemplateColumns: 'none',
                gridTemplateRows: '1fr 1fr',
              },
            }}
          >
            <div css={imagePane}>
              <img src={require('./images/frosch.jpg')} />
            </div>

            <div css={imagePane}>
              <img src={require('./images/vulkan.jpg')} />
            </div>
          </div>
        </div>

        <div css={[center, narrow]}>
          <p css={{ marginTop: '2rem', marginBottom: '2rem', fontWeight: 300 }}>
            <strong css={oswald}>Schlafen war gestern.</strong> Costa Rica setzt
            auf Eco-Tourismus. Unvergesslich werden Nächte in einer
            Regenwaldhütte oder einzigartigen Eco Lodge inmitten der Natur. Noch
            bevor es hell wird, beginnt die Show. Alles überlagert sich wie bei
            einem Orchester: Brüllen, Zirpen, Pfeifen, Rufen, Schreien. Die
            Sonne flutet das Dschungelset mit Licht. Du willst nur eines –
            hineinwandern in diese Landschaft. Es folgen lange Kamerafahrten mit
            Zooms und Schwenks. Zooms auf Armeisenheere, die ihren Highway über
            den Weg gelegt haben. Schwenks auf Kolibris, die so schnell sind,
            dass man sich für Augen wie Gehirn eine Zeitraffereinstellung
            wünscht.
          </p>
        </div>

        <div
          css={{
            display: 'grid',
            gridGap: 9,
            gridTemplateColumns: '2fr 1.5fr',

            '@media (max-width: 700px)': {
              gridTemplateColumns: 'none',
              gridTemplateRows: '2fr 1.5fr',
            },
          }}
        >
          <div css={imagePane}>
            <img src={require('./images/kolibri.jpg')} />
          </div>
          <div
            css={[
              lora,
              center,
              {
                background: '#84B09C',
                color: Color.White,
                display: 'grid',
                alignItems: 'center',
                padding: '3rem 1rem',
                fontStyle: 'italic',
                textTransform: 'uppercase',
                fontSize: 40,
                lineHeight: '70px',
              },
            ]}
          >
            »Pura vida ist nicht nur eine Begrüßung - Pura Vida ist eine
            Einstellung.«
          </div>
        </div>

        <div>
          <p
            css={[
              center,
              narrow,
              { marginTop: '2rem', marginBottom: '2rem', fontWeight: 300 },
            ]}
          >
            Auf der Landkarte sehen die Umrisse von Costa Rica aus wie eine
            Nabelschnur, die Nord- mit Südamerika verbindet. Seit es die
            Kontinente vor Millionen Jahren zueinander zog, wanderten aus Nord
            wie Süd Pflanzen und Tierarten ein. Auf 0,03% der Landoberfläche,
            ein wenig größer wie Niedersachsen, flattern, kriechen und krabbeln
            über 500.000 Arten. Das sind fünf Prozent der Erde. Darunter richtig
            schräge Vögel.
          </p>
        </div>

        <div
          css={{
            display: 'grid',
            gridGap: 9,
            gridTemplateColumns: '2fr 1fr',

            '@media (max-width: 700px)': {
              gridTemplateColumns: 'none',
              gridTemplateRows: '1fr 1fr',
            },
          }}
        >
          <div css={imagePane}>
            <img src={require('./images/kueste.jpg')} />
          </div>
          <div css={imagePane}>
            <img src={require('./images/papageien.jpg')} />
          </div>
        </div>

        <div
          css={{
            background: `no-repeat url(${require('./images/bg.png')}) center`,
            padding: '10rem 0',
            margin: '9px 0',
            overflow: 'hidden',

            '@media (max-width: 700px)': {
              paddingTop: '7rem',
              paddingBottom: '0',
            },
          }}
        >
          <div
            css={[
              {
                position: 'relative',
                background: Color.Green,
                color: Color.White,
                maxWidth: 700,
                margin: '0 auto',
                padding: '2rem',
              },
              center,
            ]}
          >
            <h2 css={[oswald, header]}>Wir jagen den Quetzal</h2>

            <p css={lora}>
              Der Vogel, den alle bestaunen wollen, ist 35 Zentimeter groß und
              200 Gramm schwer. Er versteckt sich hoch oben in den immergrünen
              Wolken- und Nebelwäldern. Die Rede ist vom Quetzal. Plötzlich
              huscht eine Gestalt wie ein Regenbogen über die
              Dschungel-Leinwand. »Da!«. »Jetzt dort!«. Sein Gefieder schillert
              smaragdgrün, sein Bauch scharlachrot. Die zwei Schwanzfedern
              gleichen einem Brautschleier und werden bis zu 80 Zentimeter lang.
              Der Göttervogel könnte locker in einem der nächsten Avatar Filme
              mitspielen.
            </p>
            <p css={lora}>
              Östlich des Los Quetzales Nationalparks wirft die Straße La
              Carretera Interamericana Sur ihre Schleifen in die Cordillera de
              Talamanca. Am Pass Cerro de la Muerte fällt auf 3.451 Metern das
              Atmen schwer. Nur Wanderer schnaufen höher zum Cerro Chirripó, dem
              mit 3.820 Metern höchsten Berg Costa Ricas. Die Landschaft des
              360°-Panoramas ist fremd und geheimnisvoll.
            </p>

            <img
              role="presentation"
              src={require('./images/quetzal.png')}
              css={{
                position: 'absolute',
                top: '0',
                width: '60%',
                left: '100%',
                pointerEvents: 'none',
                transform: 'translate(-40%, -15%)',
              }}
            />
          </div>
        </div>

        <div css={{ position: 'relative' }}>
          <p
            css={[
              oswald,
              center,
              {
                background: `no-repeat url(${require('./images/collage.jpg')}) center`,
                backgroundSize: 'cover',
                margin: 0,
                marginBottom: 9,

                fontSize: '83px',
                color: '#FFFFFF',
                letterSpacing: '0',
                lineHeight: '117px',
                textShadow: '0 2px 4px rgba(0,0,0,0.50)',

                '::before': {
                  display: 'block',
                  content: '""',
                  paddingTop: '40%',
                },
                '::after': {
                  display: 'block',
                  content: '""',
                  paddingTop: '40%',
                },
              },
            ]}
          >
            Schön und wild <br /> liegen nah
            <br /> beieinander.
          </p>
        </div>

        <div
          css={{
            position: 'relative',
            background: '#84B09C',
            color: Color.White,
            padding: '2rem 0',
          }}
        >
          <h2 css={[oswald, narrow, center, header, { marginBottom: '1rem' }]}>
            Von 3820 Meter auf Null
          </h2>

          <p
            css={[
              narrow,
              center,
              lora,
              { paddingRight: '4rem', paddingLeft: '4rem' },
            ]}
          >
            Nach einer schweißtreibenden Tour wollen Wanderer nur eines – wie
            ein Faultier abhängen. Nirgends geht das so gut wie an der Karibik
            Küste, wo einem Reggae-Klänge durch schwülheiße Nächte tragen. Zwei
            Hotspots buhlen um die Gunst: Der Tortuguero Nationalpark im Norden
            mit Flusskanälen und Lagunen – auch bekannt als der »kleine Amazonas
            Costa Ricas«. Im Süden der Nationalpark Cahuita. Kokospalmen recken
            sich verführerisch Sonne und Meer entgegen. Die DNA Deines Urlaubs
            stimmt. Du bist völlig relaxt, kaufst dir einen
            Papaya-Mango-Ananas-Banane-Kokos-Smoothie gräbst die Füße in den
            Sand und hebst den Drink auf Costa Rica. Pura Vida – das reine,
            einfache Leben!
          </p>

          <img
            src={require('./images/faultier.png')}
            role="presentation"
            css={{
              position: 'absolute',
              bottom: 0,
              right: 0,
              transform: 'translateY(34%)',

              width: '15%',
            }}
          />
        </div>

        <div>
          <h2 css={[oswald, center, header, { color: '#84B09C' }]}>
            Hang Loose in Costa Rica!
          </h2>

          <Slideshow
            items={[
              require('./images/slides/Slideshow_1.jpg'),
              require('./images/slides/Slideshow_2.jpg'),
              require('./images/slides/Slideshow_3.jpg'),
              require('./images/slides/Slideshow_4.jpg'),
              require('./images/slides/Slideshow_5.jpg'),
              require('./images/slides/Slideshow_6.jpg'),
            ]}
          />
        </div>

        <Sweepstakes />
      </div>
    </React.Fragment>
  );
}

function SoundBox(props: {
  title: string;
  content: string;
  image: string;
  background: string;
  onClick: any;
}) {
  return (
    <div
      css={[soundBox, { backgroundImage: `url(${props.background})` }]}
      onClick={props.onClick}
    >
      <img css={circleImage} src={props.image} alt={props.title} />

      <SoundButton src={require('./images/audio.png')} />

      <strong css={oswald}>{props.title}</strong>

      <p>{props.content}</p>
    </div>
  );
}

const soundBox = css({
  // display: 'grid',
  padding: '2rem 1rem',
  background: '#84B09C',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  cursor: 'pointer',
  transition: 'backgroundColor 500ms ease-out',

  ':hover': {
    backgroundColor: 'hsla(153, 22%, 50%, 1)',
  },
});

const circleImage = css({
  display: 'block',
  margin: '1rem auto',
  maxWidth: '80%',
});

const SoundButton = styled.img({
  display: 'block',
  margin: '2rem auto',
  width: 60,
  opacity: 0.6,
});
