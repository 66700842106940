import { css } from '@emotion/core';

export const questions = [
  {
    title:
      'Wie viele Tier-Arten krabbeln, kriechen, klettern, fliegen, leben in Costa Rica?',
    answers: [
      { title: 'Über 300.000 Arten', value: '300k' },
      { title: 'Über 400.000 Arten', value: '400k' },
      { title: 'Über 500.000 Arten', value: '500k' },
    ],
  },
];

export const infoQuestions = [
  {
    id: 'newsletter_costa_rica',
    text: 'Ich möchte den Newsletter von Visit Costa Rica bestellen.',
  },
  {
    id: 'newsletter_globetrotter',
    text: 'Ich möchte den Newsletter von Globetrotter Ausrüstung bestellen.',
  },
];

export const fonts = ['Oswald:300,400,500', 'Lora:400'];

export enum Color {
  Green = '#00421B',
  White = '#FFF',
  Background = '#FCF7ED',
}

export const lora = css({
  fontFamily: 'Lora',
  fontSize: '20px',
  letterSpacing: '0.39px',
  lineHeight: '30px',

  '@media (max-width: 700px)': {
    fontSize: '20px',
    lineHeight: '30px',
  },
});

export const oswald = css({
  fontSize: '20px',
  fontFamily: 'Oswald',
  fontWeight: 500,
  lineHeight: '30px',
  letterSpacing: '0.43px',
  textTransform: 'uppercase',

  '@media (max-width: 700px)': {
    fontSize: '20px',
    lineHeight: '30px',
  },
});

export const center = css({ textAlign: 'center' });

export const narrow = css({
  maxWidth: 800,
  margin: '0 auto',
  padding: '0 2rem',
});

export const header = css({
  fontSize: '34px',
  letterSpacing: '2.79px',
  lineHeight: '40px',
});

export const imagePane = css({
  background: '#CCC',
  height: 600,

  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },

  '@media (max-width: 700px)': {
    height: 300,
  },
});

export const globalStyles = css`
  body {
    color: ${Color.Green};
    margin: 0;
  }

  * {
    font-size: 20px;
    font-family: Oswald;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0.2px;

    box-sizing: border-box;
  }

  a {
    color: ${Color.Green};
    text-decoration: none;
    font-size: 1em;

    :hover {
      text-decoration: underline;
    }
  }

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background: transparent;
  }

  ::-webkit-scrollbar-track {
    background: #fff;
  }

  ::-webkit-scrollbar-thumb {
    background: #000;
    border-radius: 10px;
    border: 4px solid #fff;
  }

  body {
    overscroll-behavior: none;
  }

  // @media (max-width: 700px) {
  //   * {
  //     font-size: 14px;
  //     line-height: 20px;
  //   }
  // }
`;
